import React, { Component } from 'react';
// import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import styled from 'styled-components';

// import { removeCookies } from '../helpers';
import '../globalStyles.css';
import Layout from '../components/Layout';

const PrivacyPolicyContainer = styled.div`
  color: var(--color-dark-grey);
  font-size: 17px;
  width: 55%;
  margin: auto;
  margin-bottom: 120px;

  @media (max-width: 1620px) {
     width: 65%
   }

  @media (max-width: 1350px) {
    margin-bottom: 10%;
    font-size: 16px;
    width: 70%;
  }

  @media (max-width: 1023px) {
       width: 85%;
  }

  @media (max-width: 550px) {
    font-size: 14px;
  }

  & h1 {
    font-size: 55px;
    text-align: center;
    margin-top: 120px;
    margin-bottom: 5%;
    
    @media (max-width: 1350px) {
      font-size: 50px;
      margin-top: 10%;
    }
    
    @media (max-width: 550px) {
      font-size: 35px;
    }
  }

  & h4 {
   font-size: 40px;
   text-align: center;
   margin-top: 0;
   margin-bottom: 80px;
    
   @media (max-width: 1350px) {
     font-size: 30px;
     margin-bottom: 10%;
   }
    
   @media (max-width: 550px) {
     font-size: 22px;
   }
 }
    
 & h2 {
   font-size: 25px;
   margin-top: 50px;
    
   @media (max-width: 1350px) {
     font-size: 20px;
   }
    
   @media (max-width: 550px) {
     font-size: 16px;
   }
 }
    
 & h3 {
   font-size: 17px;
   margin-top: 35px;
   font-weight: 700;
    
   @media (max-width: 550px) {
     font-size: 15px;
   }
 }
    
 & a {
   color: var(--color-dark-grey);
   text-decoration: none;
   transition: all ease 0.3s;
   background-size: auto 175%;
   box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
   0 4px 0 -3px rgba(52, 191, 201, 0.6),
   inset 0 0 0 0 rgba(52, 191, 201, 0.6);
 }
    
 & a:hover {
   background-position-y: 105%;
   color: var(--color-white);
   box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
   0 0px 0 2px rgba(52, 191, 201, 0.8),
   inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
 }
    
 & p {
   font-size: 17px;
    
   @media (max-width: 1350px) {
     font-size: 16px;
   }
    
   @media (max-width: 550px) {
     font-size: 14px;
   }
 }
`;

// const PrivacyPolicyText = styled.div`
//   margin: auto;
//   width: 55%;

//   @media (max-width: 1620px) {
//     width: 65%
//   }

//   @media (max-width: 1350px) {
//     width: 70%
//   }

//   @media (max-width: 1023px) {
//     width: 85%;
//   }

//   & h1 {
//     font-size: 60px;
//     text-align: center;
//     margin-top: 120px;
//     margin-bottom: 0;

//     @media (max-width: 1350px) {
//       font-size: 50px;
//       margin-top: 10%;
//     }

//     @media (max-width: 550px) {
//       font-size: 35px;
//     }
//   }

//   & h4 {
//     font-size: 40px;
//     text-align: center;
//     margin-top: 0;
//     margin-bottom: 80px;

//     @media (max-width: 1350px) {
//       font-size: 30px;
//       margin-bottom: 10%;
//     }

//     @media (max-width: 550px) {
//       font-size: 22px;
//     }
//   }

//   & h2 {
//     font-size: 25px;
//     margin-top: 50px;

//     @media (max-width: 1350px) {
//       font-size: 20px;
//     }

//     @media (max-width: 550px) {
//       font-size: 16px;
//     }
//   }

//   & h3 {
//     font-size: 17px;
//     margin-top: 35px;
//     font-weight: 700;

//     @media (max-width: 550px) {
//       font-size: 15px;
//     }
//   }

//   & a {
//     color: var(--color-dark-grey);
//     text-decoration: none;
//     transition: all ease 0.3s;
//     background-size: auto 175%;
//     box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
//     0 4px 0 -3px rgba(52, 191, 201, 0.6),
//     inset 0 0 0 0 rgba(52, 191, 201, 0.6);
//   }

//   & a:hover {
//     background-position-y: 105%;
//     color: var(--color-white);
//     box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
//     0 0px 0 2px rgba(52, 191, 201, 0.8),
//     inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
//   }

//   & p {
//     font-size: 17px;

//     @media (max-width: 1350px) {
//       font-size: 16px;
//     }

//     @media (max-width: 550px) {
//       font-size: 14px;
//     }
//   }
// `;
// const ButtonArea = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 55%;
//   margin: auto;
//   margin-top: 60px;

//   @media (max-width: 1620px) {
//     width: 65%;
//   }

//   @media (max-width: 1350px) {
//     width: 70%;
//   }

//   @media (max-width: 1023px) {
//     width: 85%;
//   }

//   & .button {
//     text-decoration: none;
//     padding: 7px 0;
//     text-align: center;
//   }

//   & button, .button {
//     color: var(--color-teal);
//     width: 270px;
//     font-weight: 700;

//     & a {
//       text-decoration: none;
//       color: var(--color-teal);
//     }

//     &:last-child {
//       margin-top: 30px;
//     }

//     @media (max-width: 1350px) {
//       font-size: 18px;
//       width: 250px;
//     }

//     @media (max-width: 550px) {
//       font-size: 15px;
//       width: 213px;
//     }
//   }
// `;
export default class PrivacyPolicy extends Component {
  static propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
  };

  componentDidMount() {
    if (window.dr_cookiebanner_options) {
      window.dr_cookiebanner_options.gtmInit();
      window.setTimeout(() => window.dr_cookiebanner_options.gtmInit(), 300);
    }
  }

  componentDidUpdate() {
    if (window.dr_cookiebanner_options) {
      window.dr_cookiebanner_options.gtmInit();
      window.setTimeout(() => window.dr_cookiebanner_options.gtmInit(), 300);
    }
  }

  render() {
    const { data, pageContext } = this.props;

    let canonicalLinkPrefix;

    if (pageContext.locale === 'en') {
      canonicalLinkPrefix = 'https://presono.com/en';
    } else {
      canonicalLinkPrefix = 'https://presono.com';
    }

    return (
      <Layout currentPath={pageContext.originalPath}>
        <Helmet>
          <title>{`${data?.mdx?.frontmatter?.title} | presono`}</title>
          <meta
            name="description"
            content={data?.mdx?.frontmatter?.snippetTextPrivacyPolicy}
          />

          <link rel="canonical" href={canonicalLinkPrefix + pageContext.originalPath} />

          <meta property="og:title" content={data?.mdx?.frontmatter?.title} />
          <meta property="og:description" content={data?.mdx?.frontmatter?.snippetTextPrivacyPolicy} />
          <meta name="twitter:title" content={data?.mdx?.frontmatter?.title} />
          <meta name="twitter:description" content={data?.mdx?.frontmatter?.snippetTextPrivacyPolicy} />
        </Helmet>
        <PrivacyPolicyContainer tabIndex="-1">
          {/* <PrivacyPolicyText>
            <MDXRenderer>{data?.mdx?.body || 'TEXT NOT FOUND'}</MDXRenderer>
          </PrivacyPolicyText>
          <ButtonArea>
            <a
              href={data?.mdx?.frontmatter?.privacyPolicyPDF}
              download={data?.mdx?.frontmatter?.downloadNamePrivacyPolicy}
              className="button"
            >
              <span>{data?.mdx?.frontmatter?.downloadButtonPrivacyPolicy}</span>
            </a>
            <button
              type="button"
              onClick={() => {
                if (typeof window !== 'undefined') {
                  localStorage.clear();
                  sessionStorage.clear();
                  removeCookies();
                  window.setTimeout(() => {
                    window.location.reload();
                  }, 100);
                }
              }}
            >
              {data?.mdx?.frontmatter?.disableCookiesButton}
            </button>
          </ButtonArea> */}
          <div id="dr-privacynotice-div" />
          <noscript>
            <iframe width="100%" frameBorder="0" style={{ minHeight: '400px' }} src="https://webcache-eu.datareporter.eu/c/004d9921-f1a9-4162-a568-70a955e92227/74WCWY1Rl72N/d4X/privacynotice_noscript.html" />
          </noscript>
        </PrivacyPolicyContainer>
      </Layout>
    );
  }
}

export const query = graphql`
query PrivacyPolicyQuery($locale: String!) {
  mdx(
    fields: { locale: { eq: $locale } },
    frontmatter: { layout: { eq: "privacyPolicy" } }
  ) {
    frontmatter {
      title
      snippetTextPrivacyPolicy    
      privacyPolicyPDF
      downloadButtonPrivacyPolicy
      downloadNamePrivacyPolicy
      disableCookiesButton
    }
    body
  }
}
`;
